import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: 'detail/:id',
    loadChildren: './detail/detail.module#DetailPageModule'
  },
  {
    path: 'slides/:id',
    loadChildren: './slides/slides.module#SlidesPageModule'
  },
  { path: 'cart', 
    loadChildren: './cart/cart.module#CartPageModule' 
  },
  { path: 'search', loadChildren: './search/search.module#SearchPageModule' },
  { path: 'contact', loadChildren: './contact/contact.module#ContactPageModule' },
  { path: 'contribute', loadChildren: './contribute/contribute.module#ContributePageModule' },
  { path: 'merchandise', loadChildren: './merchandise/merchandise.module#MerchandisePageModule' },
  { path: 'merchandise-detail', loadChildren: './merchandise-detail/merchandise-detail.module#MerchandiseDetailPageModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
