import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor(private menu: MenuController) { }

  openLeftMenu() {
    this.menu.open('start');
  }

  openRightMenu() {
    this.menu.open('end');
  }

  // genre: any = document.getElementsByName('genre');
  // selectedAll: any;

  
  // selectAll() {
  //   for (var i = 0; i < this.genre.length; i++) {
  //     this.genre[i].selected = this.selectedAll;
  //   }
  // }
  // checkIfAllSelected() {
  //   this.selectedAll = this.genre.every(function(item:any) {
  //       return item.selected == true;
  //     })
  // }

}
