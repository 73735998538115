import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClientApiService {

  dataMangas = [
    {
      id: 1,
      title: 'Gunnm: Last Order',
      author: 'Yukito Kishiro',
      cover: '/assets/imgs/cover.jpg',
      thumbnail: 'https://f01.mrcdn.info/file/mrportal/i/b/k/a/e.gDIUa77L.jpg',
      summary: 'After being killed by the doll-bomb in the original manga series, Alita is resurrected by Desty Nova. The Tipharean scientist reconstructs her brain - bringing forth fragments of her past - and gives her a new body that is able to fully utilize her incredible strength. As she awakens, Alita finds herself in a devastated Tiphares, where a battle between Tiphareans with brain-chips and those without is raging. At the same time, the automatic security system goes on a rampage, killing anything that moves. Last Order takes Alita&apos;s adventures into outer space, as she encounters old associates and meets new faces - both friend and foe - and begins to piece together memories of her past.',
      genre: ['Action', 'Adventure', 'Fantasy'],
      link: '/detail/1',
      chapterstats: 'Completed',
      newchapter: 1
    },
    {
      id: 2,
      title: 'A.I.C.O Incarnation',
      author: 'Hiroaki Michiaki',
      cover: 'https://vgfiles.nabstudio.com/portal/6c436d86a5fad42f65d9de5433135582_24827_cover.jpg',
      thumbnail: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2018/04/09231712/01-800x1200.jpg',
      summary: 'The people and cyborgs of the Scrapyard live beneath the floating city of Tiphares, whose inhabitants dump their junk in the Scrapyard and rule above its inhabitants. One day Ido, a former Tipharean citizen and doctor specializing in cyborg repairs, finds the intact head of young cyborg-girl in a vast pile of scrap. He takes her in, gives her a body and the name Alita. Alita then discovers long forgotten fighting techniques hidden in her body and decides to become a hunter-warrior; a bounty collector employed by the factories of Tiphares.',
      genre: ['Action', 'Fantasy'],
      link: '/detail/2',
      chapterstats: 'Ongoing',
      newchapter: 1
    },
    {
      id: 3,
      title: 'One Punch-Man',
      author: 'Yusuke Murata',
      cover: 'https://f01.mrcdn.info/file/mrportal/j/2/1/b/Hv.7LxBBEgW.png',
      thumbnail: 'https://vgfiles.nabstudio.com/portal/aaedd94de0db5e6067407d69ce560167_358279_thumbnail.jpg',
      summary: '“I’m just an average guy who serves as an average hero.” In a world of superheroes and villains, Saitama appears to be a plain man -- from his lifeless expression to his sluggish personality, nothing stands out. But once danger strikes, he’s an insanely powerful superhero that can take down villains with a single punch! Although his strength keeps the city safe, Saitama can’t help but grow bored with how easily his opponents are defeated.',
      genre: ['Action', 'Fantasy'],
      link: '/detail/3',
      chapterstats: 'Completed',
      newchapter: 2
    },
    {
      id: 4,
      title: 'The Rising of the Shield Hero',
      author: 'Kyu Aiya; Yusagi Aneko',
      cover: 'https://vgfiles.nabstudio.com/portal/c7f50ceab7f8680c1a2ceb226495497d_241688_cover.jpg',
      thumbnail: 'https://f01.mrcdn.info/file/mrportal/i/3/v/k/f-.erUX8DEk.jpg',
      summary: '“Naofumi Iwatani, an uncharismatic Otaku who spends his days on games and manga, suddenly finds himself summoned to a parallel universe! He discovers he is one of four heroes equipped with legendary weapons and tasked with saving the world from its prophesied destruction. As the Shield Hero, the weakest of the heroes, all is not as it seems. Naofumi is soon alone, penniless, and betraved.',
      genre: ['Action', 'Fantasy', 'Drama'],
      link: '/detail/4',
      chapterstats: 'Ongoing',
      newchapter: 1
    },
    {
      id: 5,
      title: 'The Rising of the Shield Hero',
      author: 'Kyu Aiya; Yusagi Aneko',
      cover: 'https://vgfiles.nabstudio.com/portal/c7f50ceab7f8680c1a2ceb226495497d_241688_cover.jpg',
      thumbnail: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2017/02/27202525/aceofthediamondvolume1-450x675.png',
      summary: '“Naofumi Iwatani, an uncharismatic Otaku who spends his days on games and manga, suddenly finds himself summoned to a parallel universe! He discovers he is one of four heroes equipped with legendary weapons and tasked with saving the world from its prophesied destruction. As the Shield Hero, the weakest of the heroes, all is not as it seems. Naofumi is soon alone, penniless, and betraved.',
      genre: ['Action', 'Fantasy', 'Drama'],
      link: '/detail/4',
      chapterstats: 'Ongoing',
      newchapter: 1
    },
  ];

  dataVolumes = [
    {
      id: 1, // id mangas
      details: [
        {
          volume_id: 1,
          volume: 'Volume 1',
          cover: 'https://vgfiles.nabstudio.com/portal/aaedd94de0db5e6067407d69ce560167_358279_thumbnail.jpg',
          link: '/slides/1'
        },
        {
          volume_id: 2,
          volume: 'Volume 2',
          cover: 'https://vgfiles.nabstudio.com/portal/aaedd94de0db5e6067407d69ce560167_358279_thumbnail.jpg',
          link: '/slides/2'
        },

      ],
    },
    {
      id: 2,
      details: [
        {
          volume_id: 11,
          volume: 'Volume 1',
          cover: 'https://vgfiles.nabstudio.com/portal/eed9eb8a02a4ff979aafb7be0ccac2ca_90619_Thumbnail.jpg',
          link: '/slides/11'
        },
        {
          volume_id: 12,
          volume: 'Volume 2',
          cover: 'https://vgfiles.nabstudio.com/portal/eed9eb8a02a4ff979aafb7be0ccac2ca_90619_Thumbnail.jpg',
          link: '/slides/12'
        },
      ],
    },
    {
      id: 3,
      details: [
        {
          volume_id: 21,
          volume: 'Volume 1',
          cover: 'https://vgfiles.nabstudio.com/portal/aaedd94de0db5e6067407d69ce560167_358279_thumbnail.jpg',
          link: '/slides/21'
        },
        {
          volume_id: 22,
          volume: 'Volume 2',
          cover: 'https://vgfiles.nabstudio.com/portal/aaedd94de0db5e6067407d69ce560167_358279_thumbnail.jpg',
          link: '/slides/22'
        },
      ],
    },
    {
      id: 4,
      details: [
        {
          volume_id: 41,
          volume: 'Volume 1',
          cover: 'https://vgfiles.nabstudio.com/portal/aaedd94de0db5e6067407d69ce560167_358279_thumbnail.jpg',
          link: '/slides/41'
        },
        {
          volume_id: 42,
          volume: 'Volume 2',
          cover: 'https://vgfiles.nabstudio.com/portal/aaedd94de0db5e6067407d69ce560167_358279_thumbnail.jpg',
          link: '/slides/42'
        },
      ],
    },
  ];

  dataSlides = [
    {
      volume_id: 1,
      slides: [
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234439/0-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234423/3-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234427/2-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234418/4-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234405/7-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234409/6-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234400/8-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234355/9-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234351/10-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234346/11-800x1171.jpg',
        }
      ]
    },
    {
      volume_id: 2,
      slides: [
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234106/45-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234244/24-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234240/25-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234230/27-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234225/28-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234221/29-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234216/30-800x1171.jpg',
        }
      ]
    },
    {
      volume_id: 11,
      slides: [
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2018/04/09231655/310-800x1200.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2018/04/09231648/410-800x1200.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2018/04/09231636/610-800x1200.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2018/04/09231629/71-800x1200.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2018/04/09231622/81-800x1200.jpg',
        }
      ]
    },
    {
      volume_id: 12,
      slides: [
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2018/04/09231622/81-800x1200.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2018/04/09231610/101-800x1200.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2018/04/09231605/111-800x1200.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2018/04/09231559/121-800x1200.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2018/04/09231553/131-800x1200.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2018/04/09231547/141-800x1200.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2018/04/09231542/151-800x1200.jpg',
        }
      ]
    },
    {
      volume_id: 21,
      slides: [
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234439/0-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234423/3-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234427/2-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234418/4-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234405/7-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234409/6-800x1171.jpg',
        }
      ]
    },
    {
      volume_id: 22,
      slides: [
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234439/0-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234423/3-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234427/2-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234418/4-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234405/7-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234409/6-800x1171.jpg',
        }
      ]
    },
    {
      volume_id: 41,
      slides: [
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234439/0-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234423/3-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234427/2-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234418/4-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234405/7-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234409/6-800x1171.jpg',
        }
      ]
    },
    {
      volume_id: 42,
      slides: [
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234439/0-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234423/3-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234427/2-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234418/4-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234405/7-800x1171.jpg',
        },
        {
          image_url: 'https://s3.amazonaws.com/media-us-standrad/wp-content/uploads/2016/05/12234409/6-800x1171.jpg',
        }
      ]
    }

  ]


  constructor() { }

  GetMangaList() {
    return this.dataMangas;
  }

  GetVolumeList(id: number) {
    let dv = this.dataVolumes;

    return new Promise(function (resolve) {
      dv.forEach((data: any) => {
        if (data.id == id) {
          resolve(data.details);
        }
      });
    });
  }

  GetSlideList(volume_id: number) {
    let ds = this.dataSlides;

    return new Promise(function (resolve) {
      ds.forEach((data: any) => {
        if (data.volume_id == volume_id) {
          resolve(data.slides);
        }
      });
    });
  }

  getAllMangaInfoByVolumeId(id: number) {
    var dataResult = { 'manga': null, 'volume': null, 'slides': null };

    let dm = this.dataMangas;
    let dv = this.dataVolumes;
    let ds = this.dataSlides;

    return new Promise(function (resolve) {
      dv.forEach((data: any) => {
        var dd = data.details;

        dd.forEach((details: any) => {
          if (details.volume_id == id) {
            dataResult.volume = details;
            dataResult.volume.id = data.id;
          }
        });
      });

      ds.forEach((data: any) => {
        if (data.volume_id == id) {
          dataResult.slides = data;
        }
      });

      dm.forEach((data: any) => {
        if (data.id == dataResult.volume.id) {
          dataResult.manga = data;
        }
      });


      resolve(dataResult);
    });





  }

}
